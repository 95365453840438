import { useEffect, useState, useContext, useRef } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate, useLocation, useOutletContext } from "react-router-dom";
import { Row, Col, Descriptions, Divider, Modal, Space, Grid, Typography, Tag, Button } from 'antd';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import ReactPlayer from 'react-player';
import invert from 'invert-color'
import { Context as AuthContext } from '../../context/AuthContext';
import { APIRequest, isBadResponse, getFile, fileMarkedOpen } from '../../models/APIRequest';
import { axiosError } from '../../components/Notifications';
import ApiErrorResult from '../../components/ApiErrorResult';
import LoadingBlock from '../../components/LoadingBlock';
import DownloadModal from '../../components/DownloadModal';
import ShareModal from '../../components/ShareModal';
import BasketButton from '../../components/BasketButton';
import FileEditWidget from '../../components/file/FileEditWidget';
import FileDuplicateWidget from '../../components/file/FileDuplicateWidget';
import FileDeleteModal from '../../components/file/FileDeleteModal';
import FileStatus from '../../components/file/FileStatus';
import FileModalNavigation from '../../components/list/FileModalNavigation';
import { isContributor, canEditFile } from '../../utils/AccessRightUtils';
import { checkIsAlreadyInCart } from '../../utils/CartUtils';
import { convertFileSizeToHuman, convertDurationToHuman } from '../../utils/FileUtils';
import { getShortFormat, getShortTimeFormat } from '../../utils/DateUtils';
import { nl2br } from '../../utils/StringUtils';
import {API_HEADER_KEY, API_HEADER_VALUE, STICKER_NEW_COLOR} from '../../Constant';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import FilePdfViewer from '../../components/file/FilePdfViewer';

export default function MediaFileModal(props) {
  const TAG = 'MediaFileModal'

  //Template
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams()
  const [ onDeleteFile, onUpdateFile, getParentName, getParentStates, loadMore ] = useOutletContext()
  const { useBreakpoint } = Grid
  const { Paragraph, Text } = Typography
  const screens = useBreakpoint()
  const { windowWidth, windowHeight } = useWindowDimensions()
  const idFile = (props?.idFile) ? props.idFile : params?.idFile
  const fileCode = (props?.fileCode) ? props.fileCode : params?.fileCode
  const showNavigation = (!location.pathname.match(/\/basket\/file/) && getParentName() === "MediaScreen")
  const parentIsShare = (getParentName() === "MediaShareScreen")
  const parentShareStates = (getParentName() === "MediaShareScreen") ? getParentStates() : null
  const canShare = (!parentIsShare)
  const canAddBasket = (!parentIsShare)
  const previewContainerRef = useRef()
  const youtubeEmbedUrl = 'https://www.youtube.com/embed/%code'

  //State
  const { authState } = useContext(AuthContext)
  const DEFAULT_STATES = {
    isAlreadyInCart: false,
    showModal: true,
    isLoaded: (props?.file) ? true : false,
    error: false,
    errorData: false,
    idFile: idFile,
    file: (props?.file) ? props.file : null,
    isSmallScreen: null,
    thumbWidth: '100%',
    thumbHeight: 'auto',
    previewHeight: null,
    thumbRequirePreload: (props?.file) ? (props.file?.thumb_large !== null) : false,
    thumbPreloaded: (props?.file) ? (!props.file?.thumb_large) : false
  }
  const [ states, setStates ] = useState(DEFAULT_STATES)
  const [ showPdfViewer, setShowPdfViewer ] = useState(false)
  const [ isSmallScreen, setIsSmallScreen ] = useState((screens.md === false))
  const [ videoUrl, setVideoUrl ] = useState(null)

  //Hooks
  useEffect(() => {
    console.log(TAG, 'useEffect[states.isLoaded]')

    loadData()
  }, [states.isLoaded])

  useEffect(() => {
    console.log(TAG, 'useEffect[screens]')
    
    setIsSmallScreen((screens.md === false))
  }, [screens])

  useEffect(() => {
    if (!states.isLoaded) {
      return
    }
    
    console.log(TAG, 'useEffect[states.file, authState.cart]')

    handleFileMarkedOpen()

    const thumbSize = getThumbSize(states.file)

    setStates({
      ...states,
      thumbWidth: thumbSize.thumbWidth,
      thumbHeight: thumbSize.thumbHeight,
      isAlreadyInCart: checkIsAlreadyInCart(authState?.cart, states?.file?.id)
    })
  }, [states.file, authState.cart])

  useEffect(() => {
    if (!states.isLoaded) {
      return
    }

    console.log(TAG, 'useEffect[states.file]')

    if (states.file && states.file.link && !states.file.youtube_code) {
      fetch(states.file.link, {
        method: "GET",
        headers: {
          [API_HEADER_KEY]: API_HEADER_VALUE
        },
      })
          .then(response => {
            if (!response.ok) throw new Error("Erreur de chargement de la vidéo")
            return response.blob()
          })
          .then(blob => {
            const blobUrl = URL.createObjectURL(blob)
            setVideoUrl(blobUrl)
          })
          .catch(error => console.error("Erreur vidéo :", error))
    }
  }, [states.file])

  //Hooks methods
  const getThumbSize = (file) => {
    let thumbWidth = file.thumb_large_size.width
    let thumbHeight = file.thumb_large_size.height
    const modalWidth = ((windowWidth / 100) * 80) - (24 * 2)

    if (file.thumb_large || states.file.thumb) {
      if (file.thumb_large_size.width > modalWidth) {
        thumbWidth = modalWidth
        thumbHeight = (thumbWidth / file.thumb_large_size.width) * file.thumb_large_size.height
      }

      if (thumbHeight > windowHeight - 100) {
        thumbHeight = windowHeight - 100
        thumbWidth = (thumbHeight / file.thumb_large_size.height) * file.thumb_large_size.width
      }
    }

    return {
      thumbWidth: thumbWidth,
      thumbHeight: thumbHeight,
    }
  }

  const loadData = () => {
    console.log(TAG, 'LoadData')

    if (states.isLoaded) {
      return
    } else if (!states.idFile) {
      setStates({
        ...states,
        isLoaded: true,
        error: true
      })
    }

    console.log(TAG, 'Getting data...')

    getFile(APIRequest(authState.language, authState.token), states.idFile, fileCode).then((results) => {
      if (isBadResponse(results.data)) {
        setStates({
          ...states,
          isLoaded: true,
          error: true,
          errorData: results.data.data
        })

        return
      }

      setStates({
        ...states,
        isLoaded: true,
        file: results.data.data.file,
        thumbRequirePreload: (results.data.data.file.thumb_large !== null),
        thumbPreloaded: (!results.data.data.file.thumb_large),
        error: false,
        errorData: null,
      })
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setStates({
        ...states,
        isLoaded: true,
        error: true,
        errorData: null,
      })
    })
  }

  const handleFileMarkedOpen = () => {
    fileMarkedOpen(APIRequest(authState.language, authState.token), states.idFile).then((results) => {
      if (isBadResponse(results.data)) {
        axiosError(t)
      }
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)
    })
  }

  //Template methods
  const changeFile = (file) => {
    if (!file) {
      return
    }

    const thumbSize = getThumbSize(file)

    setStates({
      ...states,
      idFile: file.id,
      file: file,
      thumbRequirePreload: (file.thumb_large !== null),
      thumbPreloaded: (!file.thumb_large),
      thumbWidth: thumbSize.thumbWidth,
      thumbHeight: thumbSize.thumbHeight,
      isAlreadyInCart: checkIsAlreadyInCart(authState?.cart, file.id)
    })
    setShowPdfViewer(false)

    navigate(location.pathname.replace(/\/file\/([0-9]{1,})/, '/file/' + file.id), { replace:true })
  }

  const setPreviewHeight = () => {
    const height = previewContainerRef?.current?.clientHeight
    console.log(TAG, 'setPreviewHeight: ' + height)

    if (!height) {
      return
    }

    setStates({ ...states, previewHeight:previewContainerRef.current.clientHeight })
  }

  const getPreview = () => {
    if (!states.file) {
      return null
    }

    if (states.file.file_type.type === 'video') {
      return (
        <div ref={previewContainerRef} style={(states.file.youtube_code) ? { width:'100%' } : { minHeight: states.previewHeight }}>
          <ReactPlayer
            url={(states.file.youtube_code) ? youtubeEmbedUrl.replace('%code', states.file.youtube_code) : videoUrl}
            controls={true}
            playing={true}
            config={{
              file: { 
                attributes: {
                  controlsList: 'nodownload'
                }
              },
              youtube: {
                playerVars: {
                  showinfo: 0,
                  controls: 1,
                  autoplay: 1
                }
              },
            }}
            //light={states.file.thumb}
            style={(states.file.youtube_code) ? undefined : { maxWidth:'100%', height:'auto' }}
            height={(states.file.youtube_code) ? (windowHeight / 1.5) : 'auto'}
            width='100%'
            onReady={() => (!states.file.youtube_code) ? null : () => setPreviewHeight()}
          />
        </div>
      )
    
    } else if (states.file.file_type.type === 'pdf' && showPdfViewer) {
      return <FilePdfViewer file={states.file} />
    
    } else if (states.file.thumb_large || states.file.thumb) {
      return (
        <div style={{
          position:'relative',
          maxWidth:'100%',
          display:'flex',
          justifyContent:'center',
          border:(states.file.file_type.type === 'image') ? '1px solid #889900' : 0
        }}>
          {(!states.thumbRequirePreload ||(states.thumbRequirePreload && !states.thumbPreloaded)) && (
            <img
              src={states.file.thumb}
              alt={states.file.title}
              style={(states.thumbRequirePreload) ? {
                filter:'blur(4px)',
                width: states.thumbWidth,
                height: states.thumbHeight,
              } : {
                maxWidth: '100%',
              }}
            />
          )}

          {(states.thumbRequirePreload) && (
            <img
              src={states.file.thumb_large}
              alt={states.file.title}
              className="img-fluid"
              onLoad={() => {
                setStates({ ...states, thumbPreloaded:true })
              }}
              onError={({ currentTarget }) => {
                setStates({ ...states, thumbPreloaded:true })
                
                currentTarget.onerror = null
                currentTarget.src = null
              }}
              style={{
                width: states.thumbWidth,
                height: states.thumbHeight,
                display: (!states.thumbRequirePreload || states.thumbPreloaded) ? 'inline-block' : 'none'
              }}
            />
          )}

          {states.file.file_type.type === 'pdf' && (
            <Button
              size='large'
              type='primary'
              icon={<EyeOutlined />}
              style={{ 
                position:'absolute',
                bottom:'50%',
                left:'50%',
                transform:'translate(-50%, -50%)',
                background:'rgba(255,255,255,.95)',
                boxShadow: '2px 2px 10px #888',
              }}
              onClick={() => setShowPdfViewer(true)}
              ghost
            >
              {t('Load PDF viewer')}
            </Button>
          )}
        </div>
      )
    } else {
      return <>No preview</>
    }
  }

  const generateDescItem = (items) => {
    return items.map(item => {
      if (!item.value) {
        return null
      }
      
      if (typeof item.value === 'object') {
        return <Descriptions.Item label={item.label} key={'itemDesc' + item.key} style={{ paddingBottom:0 }}>
          {item.value.map(val => {
            let color = "default"
            
            if (typeof item?.importantKeyIds === 'object') {
              const test = item.importantKeyIds.some(id => {
                return id === val[item.IdKey]
              })

              if (test) {
                color = "processing"
              }
            }

            let label = val[item.labelKey] + ((item?.after) ? item.after : '')

            if (item?.afterKey !== undefined && val[item.afterKey] !== null) {
              label += ' (' + val[item.afterKey] + ')'
            }

            return <Tag key={'FileAccessTag' + val[item.IdKey]} color={color}>{label}</Tag>
          })}
        </Descriptions.Item>
      
      } else if (item.value !== '') {
        return <Descriptions.Item label={item.label} key={'itemDesc' + item.key}>
          {item.value + ((item.after) ? item.after : '')}
        </Descriptions.Item>
      }

      return null
    })
  }

  const getStickers = (isNew, stickers) => {
    const views = []

    if (isNew === true) {
      views.push(
        <Tag color={STICKER_NEW_COLOR} key={'FileStickerTagNew'}>
          <Text style={{ color:invert(STICKER_NEW_COLOR, true), textTransform:'uppercase', fontSize:12 }} strong>{t('New!')}</Text>
        </Tag>
      )
    }


    if (!stickers || !stickers.length) {
      return views
    }

    stickers.forEach((item) => {
      views.push(
        <Tag color={item.color} key={'FileStickerTag' + item.id}>
          <Text style={{ color:invert(item.color, true), textTransform:'uppercase', fontSize:12 }} strong>{item.title}</Text>
        </Tag>
      )
    })

    return views
  }

  const getStatus = (status, deleted) => {
    if (deleted === true) {
      return <span style={{color:'#DD0000', fontWeight:'bold'}}><DeleteOutlined /> {t('Deleted')}</span>
    } else {
      return <FileStatus status={status} type='icon' withLabel={true} />
    }
  }

  const getModalTitle = () => {
    if (!states.isLoaded || states.error) {
      return null
    }

    return (
      <>{states.file.title}</>
    )
  }

  const getModalContent = () => {
    if (!states.isLoaded) {
      return (
        <LoadingBlock />
      )
    }

    if (states.error) {
      return (
        <ApiErrorResult errorData={states.errorData} actionTitle={t('Try to reload')} actionHandle={() => {
          setStates({...states, error:false, errorData: null, isLoaded:false})
        }} />
      )
    }

    return (
      <main>
        <div style={{ position:'relative', display:'flex', flexDirection:'column', alignItems:'center' }}>
          {showNavigation && (
            <FileModalNavigation
              getParentStates={getParentStates}
              changeFile={changeFile}
              loadMore={loadMore}
            />
          )}
          {getPreview()}
        </div>
        <Paragraph
          style={{ textAlign:'center', fontSize:16, marginTop:15 }}
        >
          {nl2br(states.file.description)}
        </Paragraph>

        <Space
          direction={isSmallScreen ? 'vertical' : 'horizontal'}
          style={{ display:'flex', justifyContent:'flex-end', flexWrap:'wrap' }}
        >
          {canAddBasket && (
            <BasketButton file={states.file} buttonProps={{ block: isSmallScreen, type:'default', size:'large' }} />
          )}
          {canShare && (
            <ShareModal files={[states.file]} widgetType="button" widgetProps={{ block: isSmallScreen, type:'default', size:'large' }} />
          )}
          <DownloadModal
            files={[states.file]}
            share={parentIsShare ? {
              id: parentShareStates?.idShare,
              secureKey: parentShareStates?.secureKey,
              password: parentShareStates?.password
            } : null}
            widgetType="button"
            widgetProps={{ block: isSmallScreen, type:'primary', size:'large' }}
          />
          {(!parentIsShare && canEditFile(authState, states.file)) && (
            <>
              <FileEditWidget
                file={states.file}
                widgetType="button"
                widgetProps={{ type:'dashed', size:'large', block:isSmallScreen }}
                onUpdateFile={(file) => {
                  if (typeof onUpdateFile === 'function') {
                    onUpdateFile(file.id, file)
                    setStates({ ...states, file: file })
                  }
                }}
                onDeleteFile={(file) => {
                  if (typeof onDeleteFile === 'function') {
                    onDeleteFile(file.id)
                  }
                }}
              />
              <FileDuplicateWidget
                file={states.file}
                widgetType="button"
                widgetProps={{ type:'dashed', size:'large', block:isSmallScreen }}
              />
              <FileDeleteModal
                files={[states.file]}
                widgetType='button'
                widgetProps={{ type:'dashed', size:'large', block:isSmallScreen, danger:true }}
                onDeletedFile={(files) => {
                  if (files && files[0] && typeof onDeleteFile === 'function') {
                    onDeleteFile(files[0])
                    setStates({ ...states, showModal: false })
                  }
                }}
              />
            </>
          )}
        </Space>
        <Divider />

        <Row gutter={[16, 16]}>
          <Col sm={24} xs={24} lg={isContributor(authState) ? 8 : 12}>
            <Descriptions title={t('Media')} layout="vertical" bordered column={1} size="small">
              {generateDescItem([
                {label: t('Type'), value: states.file.file_type.name},
                {label: t('Width'), value: states.file.media_width, after:' px'},
                {label: t('Height'), value: states.file.media_height, after:' px'},
                {label: t('Duration'), value: (states.file.media_duration) ? convertDurationToHuman(states.file.media_duration) : null},
                {label: t('Size'), value: convertFileSizeToHuman(states.file.filesize * 1000)}, //Convert kb in byte
                {label: t('Creation date'), value: moment(states.file.date_add).format(getShortTimeFormat(authState.language))},
                {label: t('Update date'), value: moment(states.file.date_upd).format(getShortTimeFormat(authState.language))},
              ])}
            </Descriptions>
          </Col>
          <Col sm={24} xs={24} lg={isContributor(authState) ? 8 : 12}>
            <Descriptions title={t('Informations')} layout="vertical" bordered column={1} size="small">
              {(states.file.is_new === true || states.file.stickers) && (
                <Descriptions.Item label={t('Stickers')} key='itemDescStickers' style={{ paddingBottom:0 }}>
                  {getStickers(states.file.is_new, states.file.stickers)}
                </Descriptions.Item>
              )}
              {generateDescItem([
                {label: t('Folders'), value: states.file.folders, IdKey: 'id', labelKey: 'title', afterKey: 'parent_title', importantKeyIds:[states.file.folder?.id]},
                {label: t('Keywords'), value: states.file.keywords, IdKey: 'id', labelKey: 'name'},
                {label: t('Author'), value: states.file.media_author},
                {label: t('Date'), value: (states.file.media_date) ? moment(states.file.media_date).format(getShortFormat(authState.language)) : null},
                {label: t('Location'), value: states.file.media_location},
                {label: t('Country'), value: states.file.country?.name},
                {label: t('Terms of use'), value: states.file.terms_of_use},
              ])}
            </Descriptions>
          </Col>
          <Col sm={24} xs={24} lg={8}>
            {isContributor(authState) && (
              <Descriptions title={t('Administration')} layout="vertical" bordered column={1} size="small">
                <Descriptions.Item label={t('Status')} key='itemDescStatus'>
                  {getStatus(states.file.status, states.file.deleted)}
                </Descriptions.Item>
                {generateDescItem([
                  {label: t('User'), value: states.file.user.name},
                  {label: t('Access rights'), value: states.file.access_rights, IdKey: 'id', labelKey: 'name'},
                  {label: t('Can be shared?'), value: states.file.broadcasts_presenter.can_share, IdKey: 'id', labelKey: 'name'},
                  {label: t('Can be downloaded?'), value: states.file.broadcasts_presenter.can_download, IdKey: 'id', labelKey: 'name'},
                  {label: t('Downloaded with watermark?'), value: states.file.broadcasts_presenter.dl_watermark, IdKey: 'id', labelKey: 'name'},
                ])}
              </Descriptions>
            )}
          </Col>
        </Row>
      </main>
    )
  }

  return (
    <Modal
      title={getModalTitle()}
      open={states.showModal}
      zIndex={100}
      width={(!screens.md) ? 'calc(100% - 32px)' : '80%'}
      onCancel={() => {
        setStates({ ...states, showModal:false })
      }}
      afterClose={() => {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1)
        } else {
            navigate(location.pathname.replace('/file/' + ((states.file?.id) ? states.file.id : idFile), ''))
        }
      }}
      footer={null}
    >
      {getModalContent()}
    </Modal>
  )
}