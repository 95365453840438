import { green, blue, red, purple, gold } from '@ant-design/colors';

//TEMPLATE
export const SIDER_WIDTH = 300

//GLOBAL MODAL
export const ENCRYPT_KEY = "f!C0877e$V9wP.d"
export const LANGUAGE_DEFAULT = "enGB"
export const LANGUAGE_AVALAIBLE = [
  { route:'fr', countryCode:'FR', title:'Français' },
  { route:'en', countryCode:'GB', title:'English' },
  //{ route:'it', countryCode:'IT', title:'Italian' }
]
export const LANGUAGE_DEFAULT_FORM_VALUE = { fr:null, en:null }
export const LANGUAGE_DEFAULT_FORM_MULTIPLE_VALUE = { fr:[], en:[] }
export const GLOBAL_MODAL_TYPES = {
  'FILE_EDIT': 'FILE_EDIT',
  'FOLDER': 'FOLDER',
  'STICKER': 'STICKER',
  'USER': 'USER',
  'WATERMARK': 'WATERMARK',
  'BROADCAST_RESTRICTION': 'BROADCAST_RESTRICTION',
};

//ACCESS RIGHTS
export const SYSTEM_RIGHT_LEVEL_COLOR = [
  green.primary,
  blue.primary,
  purple.primary,
  red.primary,
  gold.primary
]

//ASSETS
export const FOLDER_THUMB_DEFAULT = '/asset/system/folder-thumb-default.jpg'

//FOLDER
export const FOLDER_ROOT_ID = 1
export const FOLDER_TYPES = ['default', 'product']
export const FOLDER_TYPE_DEFAULT = 'default'
export const FOLDER_TYPE_PRODUCT = 'product'

//FILE & SEARCH
export const FILE_STATUS_DRAFT = 'draft'
export const FILE_STATUS_ONLINE = 'online'
export const FILE_STATUS_ARCHIVE = 'archive'
export const FILE_TYPE_SORT = ['image', 'video', 'audio', 'pdf', 'document', 'archive', 'other']
export const FILE_TYPE_IMAGE = 'image'
export const FILE_TYPE_AUDIO = 'audio'
export const FILE_TYPE_VIDEO = 'video'
export const FILE_TYPE_PDF = 'pdf'
export const FILE_TYPE_DOCUMENT = 'document'
export const FILE_TYPE_ARCHIVE = 'archive'
export const FILE_TYPE_OTHER = 'other'
export const FILE_SORT_DEFAULT_COLUMN = 'date'
export const FILE_SORT_DEFAULT_ORDER = 'desc'
export const SEARCH_SORT_DEFAULT_COLUMN = 'weight'
export const SEARCH_SORT_DEFAULT_ORDER = 'desc'

//STICKER
export const STICKER_NEW_COLOR = '#b1dfff'

//BASKET
export const BASKET_MAX_FILE = 30

//UPLOAD
export const DEFAULT_UPLOAD_PROGRESS_DATA = {
  loadedPercentage: 0,
  loaded: null,
  total: null
}
export const THUMB_FILE_ACCEPT_MIME = ['image/jpeg', 'image/png']
export const THUMB_FILE_SIZE_LIMIT = 10 //MB
export const UPLOAD_NUMBER_LIMIT = 100
export const WATERMARK_FILE_ACCEPT_MIME = ['image/png']
export const WATERMARK_FILE_SIZE_LIMIT = 2 //MB
export const UPLOAD_SIZE_LIMIT = 200 //MB
export const UPLOAD_CHUNK_SIZE = 1048576 * 8 //8MB
export const UPLOAD_ACCEPT_MIME = [
  'audio/aac',
  'application/postscript',
  'application/octet-stream',
  'video/x-msvideo',
  'image/bmp',
  'application/x-bzip',
  'application/x-bzip2',
  'text/csv',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-fontobject',
  'application/epub+zip',
  'image/gif',
  'text/html',
  'image/x-icon',
  'application/java-archive',
  'image/jpeg',
  'audio/midi',
  'video/mpeg',
  'audio/ogg',
  'video/ogg',
  'Application/ogg',
  'font/otf',
  'image/png',
  'application/pdf',
  'image/vnd.adobe.photoshop',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/x-rar',
  'application/x-rar-compressed',
  'application/rtf',
  'image/svg+xml',
  'application/x-shockwave-flash',
  'application/x-tar',
  'image/tiff',
  'font/ttf',
  'audio/x-wav',
  'audio/webm',
  'video/webm',
  'image/webp',
  'font/woff',
  'font/woff2',
  'application/xhtml+xml',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/xml',
  'application/zip',
  'application/x-zip-compressed',
  'multipart/x-zip',
  'audio/3gpp',
  'video/3gpp',
  'audio/3gpp2',
  'video/3gpp2',
  'application/x-7z-compressed',
  'video/mp4',
]
export const FILE_MIME_TYPE = {
  [FILE_TYPE_IMAGE]: [
    'image/bmp',
    'image/gif',
    'image/jpeg',
    'image/png',
  ],
  [FILE_TYPE_AUDIO]: [
    'audio/aac',
    'audio/midi',
    'audio/ogg',
    'audio/x-wav',
    'audio/webm',
    'audio/3gpp',
    'audio/3gpp2',
  ],
  [FILE_TYPE_VIDEO]: [
    'video/x-msvideo',
    'video/mpeg',
    'video/ogg',
    'video/webm',
    'video/3gpp',
    'video/3gpp2',
    'video/mp4',
  ],
  [FILE_TYPE_PDF]: [
    'application/pdf',
  ],
  [FILE_TYPE_DOCUMENT]: [
    'text/csv',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel.sheet.macroEnabled.12',
  ],
  [FILE_TYPE_ARCHIVE]: [
    'application/octet-stream',
    'application/x-bzip',
    'application/x-bzip2',
    'application/epub+zip',
    'application/java-archive',
    'application/x-rar',
    'application/x-rar-compressed',
    'application/x-tar',
    'application/zip',
    'application/x-zip-compressed',
    'multipart/x-zip',
    'application/x-7z-compressed',
  ]
}

//API
export const API_HEADER_KEY = "X-Api-Sodimedia-External-Key"
export const API_HEADER_VALUE = "7ez.vx0!Szb1p94T_xc8Z"
